/* You can add global styles to this file, and also import other style files */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
  overflow-x: hidden;
}

.form-group.validate {
  position: relative;
  label::after {
    content: "*";
    color: #eb4b4b;
    margin-left: 5px;
  }
  span.msg,
  small.msg {
    color: #eb4b4b;
    font-weight: normal;
    position: absolute;
    font-size: 12px;
  }
}
